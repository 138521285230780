/** for ProductSynology, ProductBackupBox in product Qnap*/
.panel-div.product-detail-panel {
  max-width: 100%;
  background-image: linear-gradient(45deg, #000a21, #1e365a, #192b48, #000a21);
  color: black;
  background-size: cover;
  background-color: #264773;
}

.product-ul {
  font-size: medium;
}

.panel-div.product-detail-panel-1 {
  padding-top: 5%;
  max-width: 100%;
  color: black;
  background-size: cover;
  background: linear-gradient(to bottom, #c9d9e8, transparent);
}
.subpanel-div.product-detail-subpanel {
  max-width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  padding: 100px 30px;
  font-family: "Manrope", Arial;
}
.subpanel-div.product-detail-subpanel-1 {
  padding-top: 6%;
  max-width: 700px;
  padding: 10px 25px;
  background-color: white;
}

.product-detail-inner-div {
  /* background-image: linear-gradient(145deg, transparent, #0d0d38, #0a0c4e);
   */
  color: black;

  background-color: white;
  padding-top: 150px;
  margin: 100px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  padding: 100px 0px;
  width: 70%;
  opacity: 0.95;
  margin: 0 auto;
  box-shadow: 20px 22px 40px -7px #171717;
}
.product-detail-box-text {
  margin: 0 auto;
  text-align: justify;
  opacity: 0.9;
}
.product-detail-inner-text {
  font-family: Roboto, Arial;
  text-align: center;
  width: 40%;
  margin: 0 auto;
  color: black;
  font-variant: all-small-caps;
}

.product-logo.qnap {
  width: 25%;
}

.inf-calc-data,
.restavriranje-data {
  align-self: center;
}

.product-speed-table {
  margin: 0 auto;
}

.product-title-div {
  text-align: center;
}

.product-hr {
  opacity: 0.3;
  height: 1px;
  color: green;
}

.product-table {
  margin: 0 auto;
}

.mail-to {
  color: #ff9879;
  font-size: medium;
}

.product-detail-box {
  padding: 3% 2%;
  color: black;
  margin: 0 auto;
}

.product-title {
  padding: 10px;
  font-weight: bold;
  font-size: x-large;
  background-color: #a4bddf;
  width: 100%;
  text-align: center;
  margin-bottom: 2%;
}

.product-title-div {
  font-weight: bold;
  font-size: x-large;
  font-family: "Manrope", Arial;
  margin: 20px 0px;
}

.product-description {
  padding: 10px;
  text-align: left;
}

.product-description-text {
  margin: 20px 0px;
  text-align: left;
}

.product-logo {
  width: 30%;
  display: block;
  margin: 0 auto;
  opacity: 0.9;
}

.product-img {
  max-width: 200px;
}

.product-description-img {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-buttons-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  max-width: 650px;
}

.product-text-img {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-device-width: 319px) and (orientation: portrait) {
  .product-detail-inner-div {
    width: 100%;
    padding: 0px;
  }

  .product-table {
    font-size: xx-small;
  }

  .product-description {
    padding: 0px 1px;
  }

  tr > th.product {
    padding: 1px 0px;
  }

  .product-speed-table {
    padding: 0px;
    font-size: xx-small;
  }

  .subpanel-div.product-detail-subpanel {
    padding: 0px;
  }

  .product-buttons-panel {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  .product-title {
    text-align: center;
  }

  .product-table {
    width: auto;
    overflow: hidden;
    font-size: xx-small;
    padding: 0px;
    margin: 0 auto;
  }

  .product-description {
    padding: 10px 10px;
  }

  .product-text-img {
    flex-wrap: wrap;
    justify-content: center;
  }

  .product-logo {
    width: 30%;
  }

  th {
    padding: 3px 3px !important;
  }
  tr {
    padding: 0px;
  }
  td {
    padding: 5px 1px;
  }

  table.product-speed-table {
    font-size: smaller;
    margin-bottom: 50px;
    margin: 0 auto;
  }

  .product-buttons-panel {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: unset;
  }

  .product-detail-inner-div {
    width: auto;
  }

  /* .subpanel-div.product-detail-subpanel-1 {
    width: 90%;
  } */

  .product-title-div {
    text-align: center;
  }

  .product-speed-table {
    margin: 0 auto;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
  .product-title {
    text-align: center;
  }
  .product-detail-inner-div {
    width: auto;
  }

  .product-buttons-panel {
    justify-content: center;
  }

  .subpanel-div.product-detail-subpanel-1 {
    width: 90%;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 767px) {
  .product-detail-inner-div {
    width: 90%;
  }
  .product-buttons-panel {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 690px;
  }

  .product-title-div {
    text-align: center;
  }

  .product-speed-table {
    margin: 0 auto;
  }

  .subpanel-div.product-detail-subpanel-1 {
    width: 90%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .product-title {
    text-align: center;
  }

  .product-detail-inner-div {
    width: auto;
  }

  .product-speed-table {
    margin: 0 auto;
  }

  .product-title-div {
    text-align: center;
  }
}
