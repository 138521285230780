.panel-div.vps-panel {
  width: 100%;
  max-width: 100%;
  padding-bottom: 100px;
  background-color: #c9d9e8;
}

.panel-div.vps-panel-1 {
  /*   background: linear-gradient(to bottom, #c9d9e8, transparent);
 */
  /* background: linear-gradient(to bottom, #f2f6ff, #f2f6ff, transparent);
  */
  background: linear-gradient(to bottom, #c9d9e8, #f2f6ff, #e5ecfd, #f2f6ff, transparent, transparent);
}

.vps-description {
  width: 60%;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: #adcbe8;
  background: linear-gradient(45deg, #dae9ff, #e8f0f8, #adcbe8);
  text-align: center;
  box-shadow: 5px 4px 8px rgba(66, 66, 66, 0.2);
}

.subpanel-div {
  &.vps-subpanel {
    max-width: 100%;
    display: flex;
    justify-content: center;
    color: #c5f6f6;
    text-align: center;
    padding: 10px 30px;
    background-size: 100%;
    background-color: #264773;
  }
}

.vps-table {
  display: flex;
  justify-content: center;
  margin: 20px;
  font-family: "Exo 2", Arial;
}

.vps-mail {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  background-color: #b6e6f4;
  background: linear-gradient(45deg, #b3d8fd4d, #9bedfa, #7fa7e359);
  color: black;
  opacity: 0.9;
  width: 50%;
  border-radius: 8px;
}

.vps-subtitle {
  font-size: large;
  font-weight: bold;
}
.vps-box {
  width: 100%;
  padding: 5%;
  color: whitesmoke;
  background: linear-gradient(45deg, #0d1c50, #0648a0b3, #0d1c50);
  background: radial-gradient(#0a4b9f94, #0d1c4b);
}

.vps-box-text {
  margin: 0 auto;
  text-align: left;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.vps-box-text-1,
.vps-box-text-2 {
  padding: 40px;
}

.vps-li.first {
  list-style: none;
  margin-bottom: 20px;
  font-size: medium;
}

.vps-li {
  display: flex;
  align-items: center;
  opacity: 0.9;
  text-align: left;
  color: #99ffff;
  list-style: square;
  display: list-item;
  margin-bottom: 12px;
  font-size: smaller;
}

.tooltip {
  display: block;
  margin-left: 50%;
}

.react-accordion-with-header {
  margin-top: 10px;
  background-color: #edf2f9;
  color: black;
  width: 90%;
}
.is-expanded {
  padding: 5px 1px 5px 1px !important;
  height: auto;
  max-height: 100% !important;
}

.vps-inner-div {
  margin: 100px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  padding: 100px 0px;
  width: 90%;
  opacity: 0.95;
  margin: 0 auto;
  font-size: large;
}

.vps-tooltip {
  width: 40%;
}

.vps-inner-title {
  text-align: center;
  color: #c5f6f6;
  margin: 0 auto 6% auto;
  /*  font-family: Roboto; */
  font-family: "Muli", Arial;
}

.vps-item {
  margin-bottom: 30px;
}

.vps-div {
  display: flex;
  justify-content: center;
  background-color: white;
  padding-top: 100px;
  align-items: baseline;
  flex-flow: row wrap;
  box-shadow: 10px 14px 11px rgba(66, 66, 66, 0.2);
}

.vps-div-calc {
  width: 55%;
  padding: 30px 25px;
  border: 1px solid lightblue;
  margin-bottom: 50px;
  box-sizing: border-box;
}

.vps-calc {
  width: 300px;
  position: sticky;
  top: 75px;
  margin-bottom: 50px;
  border: 1px solid lightblue;
  padding: 30px 15px;
  margin-left: 10px;
}

.bottom-text {
  font-size: smaller;
  font-style: italic;
}

.vps-item-title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 5px;
}

.vps-hr {
  width: 60%;
  margin: 0;
  color: lightgray;
}

@media only screen and (max-device-width: 319px) and (orientation: portrait) {
  .subpanel-div.vps-subpanel {
    padding: 0px;
    width: 100%;
  }

  .vps-box-text {
    flex-wrap: wrap;
  }

  .vps-table {
    font-size: xx-small;
  }
}

@media only screen and (min-device-width: 320px) {
  @media only screen and (max-device-width: 767px) {
    .vps-calc {
      width: 70%;
    }
    .vps-div {
      padding-top: 20px;
      padding-bottom: 30px;
    }

    .vps-box {
      width: 100%;
    }

    .vps-inner-div {
      width: 100%;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  @media only screen and (orientation: portrait) {
    .vps-inner-div {
      flex-wrap: wrap;
      flex-direction: row;
    }
    .vps-box-text {
      font-size: medium;
      flex-wrap: wrap;
      flex-direction: row;
    }

    .vps-box-text-1 {
      margin-top: 20px;
    }
    .vps-box-text-2 {
      margin-top: 20px;
    }

    .vps-box-text-1,
    .vps-box-text-2 {
      padding: 4px 20px;
    }

    .vps-mail {
      width: 80%;
    }
  }
  @media only screen and (orientation: landscape) {
    .vps-inner-div {
      flex-wrap: wrap;
      flex-direction: row;
    }
    .vps-box-text {
      font-size: medium;
      flex-wrap: wrap;
      flex-direction: row;
    }

    .vps-box-text-1 {
      margin-top: 20px;
    }
    .vps-box-text-2 {
      margin-top: 20px;
    }

    .vps-box-text-1,
    .vps-box-text-2 {
      padding: 4px 20px;
    }

    .vps-mail {
      width: 80%;
    }
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1366px) {
  .subpanel-div.vps-subpanel {
    background-size: cover;
  }

  .panel-div.vps-panel {
    background-color: unset;
  }
  .vps-div {
    padding: 0px;
  }

  .vps-box {
    font-size: medium;
    padding: 0px;
  }

  .vps-mail {
    width: 60%;
  }
}

.is-expanded > div {
  padding: 5px;
}

@media only screen and (max-device-width: 480px) {
  .subpanel-div.vps-subpanel {
    background-size: cover;
  }
  .vps-box-text {
    font-size: medium;
  }

  .vps-box {
    padding: 0px;
  }

  .vps-div-calc {
    padding: 10px;
  }

  @media only screen and (orientation: portrait) {
    .vps-div {
      padding-top: 20px;
    }

    .vps-div-calc {
      width: 100%;
      margin-bottom: 20px;
    }
    .vps-calc {
      margin-bottom: 40px;
      padding: 10px 20px;
    }
  }

  @media only screen and (orientation: landscape) {
    .vps-calc {
      width: 70%;
    }
    .vps-div {
      padding-bottom: 60px;
    }
  }
}
