.lang-menu {
  display: flex;
  flex-direction: row;
  height: 22px;
  position: fixed;
  width: 100%;
  top: 0px;
  align-items: center;
  justify-content: flex-start;
  opacity: 1;
  background-color: #1f1f1f;
  font-size: smaller;
}

.lang-div {
  padding-left: 20px;
  padding-right: 10px;
  font-size: x-small;
  color: lightgray;
}

.lang-img {
  max-width: 21px;
  height: auto;
  margin-right: 6px;
}

.lang-img.img-1 {
  max-width: 25px;
}