$menu-box-top-bck: #292929;
$selected-item: #f9f4ee;

.main-menu-panel {
  display: flex;
  flex-direction: row;
  height: 175px;
  position: fixed;
  width: 100%;
  top: 77px;
  align-items: center;
  justify-content: center;
  opacity: 1;
  /* animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s; */
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main-menu-top {
  background-color: $menu-box-top-bck;
  /* box-shadow: 4px 4px 11px rgba(13, 13, 13, 0.3); */
  position: fixed;
  top: 22px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /*  border-top: 0.5px solid #85a4ae;
    border-bottom: 0.5px solid #85a4ae; */
}

.main-menu-product-box {
  margin: 0px 18px;
  opacity: 0.9;
  display: flex;

  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-color: rgb(250, 250, 250);
  width: 192px !important;
  height: 147px !important;
  text-decoration: none;
  font-family: Helvetica;
  border: 4px solid transparent;
  /*filter:blur(1px); */
  /*  margin-top: 5px; */
  /* margin-right: 37px; */

  /* clip-path: polygon(
    calc(0% + 5px) calc(0% + 5px),
    /* top left */ /*calc(100% - 5px) calc(0% + 5px), */
  /* top right */ /*calc(100% - 5px) calc(100% - 5px), */
  /* bottom right */ /*calc(0% + 5px) calc(100% - 5px) /* bottom left */
  /*); */
  /* transition: clip-path 0.6s linear; */
  box-shadow: 5px 5px 16px rgba(85, 74, 74, 0.4);
  transition: 0.5s;
  height: 200px;
}

.main-menu-product-box:hover {
  /* background-color: white; */
  /* animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s; */
  border: 4px #c9ccdb solid;
  /*  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
 */
  transform: scale(1.01);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.main-menu-link {
  color: whitesmoke;
  margin: 0px 10px;
  text-decoration: none;
  font-size: medium;
  height: 50px;
  width: auto;
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  font-family: Lato, Arial;
  padding: 0px 29px;
  /*  border-left: transparent 29px solid;
  border-right: transparent 29px solid; */
  display: flex;
  align-items: center;
  transition: linear 0.4s;
}
/* 
.main-menu-link.vps {
  display: none;
} */

.main-menu-link:hover {
  background-color: #fffefe;
  color: black;
}

.main-menu-products {
  display: flex;
  flex-direction: row;
  padding-top: 11px;
  padding-bottom: 12px;
  align-items: center;
  height: 163px;
  background-color: #f9f4ee;
  box-shadow: 3px 5px 6px rgba(86, 86, 86, 0.3);
  background-image: linear-gradient(#fffefe, #e8edff);
  /*  background-image: linear-gradient(#f2efef, #fff9f9, #e6e5e5); */
}

.main-menu-product-desc {
  text-align: center;
  font-size: medium;
  color: #0e0e0e;
  margin-top: 32px;
  border-bottom: 1px solid lightgray;
}

.main-menu-img {
  margin-top: 20px;
  width: 85px;
  opacity: 0.9;
}

.main-menu-img.Backup.Box {
  width: 100px;
  opacity: 0.9;
  margin-top: 0px;
}

.burger-menu-icon {
  display: none;
}

.main-menu-links {
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.main-menu-submenu {
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  .main-menu-top {
    height: 50px;
    opacity: 1;
  }

  li {
    padding: 0 15px;
  }

  .burger-menu-icon {
    height: 22px;
    width: 36px;
  }
}

@media only screen and (orientation: landscape) and (max-device-width: 1279px) {
  @media only screen and (orientation: landscape) and (max-device-width: 1366px) {
    .main-menu-top {
      height: 40px;
    }

    .main-menu-submenu.submenu-show {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .main-menu-links.toggleMenu > div > .main-menu-link.submenu {
      font-size: small;
      border-bottom: 1px solid white;
    }

    .burger-menu-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 8px;
      left: 2px;
      position: absolute;
      height: 22px;
      width: 36px;
      color: white;
      z-index: 99999;
      cursor: pointer;
    }

    .main-menu-links.toggleMenu {
      height: auto;
      display: flex;
      flex-direction: column;
      background-color: $menu-box-top-bck;
      top: 30px;
      left: 0px;
      width: auto;
    }

    .main-menu-links.toggleMenu > div > .main-menu-link {
      font-size: medium;
      height: 55px;
    }

    .main-menu-links {
      display: none;
    }

    .main-menu-panel {
      display: none;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
  .main-menu-top {
    height: 30px;
  }

  .burger-menu-icon {
    height: auto;
    top: 7px;
    height: 15px;
    width: auto;
  }

  .main-menu-links.toggleMenu {
    top: 30px;
  }
}
@media only screen and (orientation: portrait) {
  .main-menu-submenu.submenu-show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .main-menu-links.toggleMenu > div > .main-menu-link.submenu {
    font-size: small;
    border-bottom: 1px solid white;
  }

  .burger-menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    left: 10px;
    position: absolute;
    height: 30px;
    width: 36px;
    color: white;
    z-index: 99999;
    cursor: pointer;
  }

  .main-menu-links.toggleMenu {
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: $menu-box-top-bck;
    top: 49px;
    left: 0px;
    width: auto;
  }

  .main-menu-links.toggleMenu > div > .main-menu-link {
    font-size: medium;
    height: 55px;
  }

  .main-menu-links {
    display: none;
  }

  .main-menu-panel {
    display: none;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 640px) and (orientation: landscape) {
  .main-menu-top {
    height: 30px;
  }

  .burger-menu-icon {
    top: 7px;
    left: 0px;
    height: 16px;
  }
}
