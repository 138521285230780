/* @import url("https://fonts.googleapis.com/css?family=Roboto|Exo+2|Muli|Lato|Rubik|Signika|Palanquin+Dark|Coda+Caption|Sulphur+Point|Questrial|Manrope|Raleway|Red+Hat+Display|Poppins");
 */
@import "panel-common.scss";
@import "slider.scss";
@import "slider-vps.scss";

$menu-bg: #292929;
$menu-box-top-bck: #292929;

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("../assets/fonts/Raleway-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-display: swap;
}

html {
  font-family: "Manrope", "Poppins", "Roboto", "Raleway", Helvetica, sans-serif;
  font-display: swap;
}

body {
  background-size: 100% 100%;
  margin: 0;
  width: 100%;
  color: white;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  /* -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
 */
  /*  font-family: "Manrope", "Poppins", "Roboto", "Raleway", Helvetica, sans-serif; */
}

.header {
  background-color: white;
  padding: 0px;
  display: none;
}

table {
  /*   font-family: Arial;
 */
  font-family: "Exo 2", Arial;
  border-collapse: collapse;
}

td {
  padding: 5px 10px;
  text-align: center;
  background-color: #f7f9ff;
  color: black;
  border-style: solid;
  border-color: #d7d7d7;
  border-width: 1px;
}

td.product {
  padding: 15px 10px;
  text-align: center;
  background-color: #f0f5ff;
  color: black;
  border-style: solid;
  border-color: #d7d7d7;
  border-width: 1px;
}

th {
  border-width: 1px;
  border-color: #c3e3fa;
  padding: 10px;
  text-align: center;
  background-color: #96b4d875;
  font-weight: initial;
  color: black;
}

tr.product {
  border-collapse: unset;
}

th.product {
  font-family: Arial;
  border-width: 1px;
  /* border-style: solid; */
  border-color: #616ba9;
  padding: 10px;
  text-align: center;
  background-color: #456394;
  font-weight: initial;
  color: white;
}

.content-div {
  max-width: 1000px;
}

.content {
  position: relative;
  /*  font-family: Raleway; */
  font-family: "Manrope", Arial;
  color: #afd4e0;
  max-width: 100%;
}

.offer-title {
  font-size: x-large;
  font-weight: bold;
  font-family: "Roboto", Arial;
}

break {
  flex-basis: 100%;
}

.terms-div {
  padding: 0px 25px;
  text-align: justify;
}

.middle-subtitle {
  width: 40%;
  background-color: #3d4f84;
  padding: 10px;
  color: white;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size: medium;
  box-shadow: 3px 3px 11px rgba(13, 13, 13, 0.3);
  text-align: center;
  font-family: Roboto, Arial;
}

.middle-subtitle.middle-1 {
  background-color: #eaeaea;
  color: black;
  letter-spacing: 1px;
}

.middle-subtitle.middle-2 {
  margin-bottom: 40px;
  /* font-variant-caps: all-petite-caps; */
  letter-spacing: 2px;
  /*background-color: #3d4f84; */
  background-color: #eaeaea;
  color: black;
}

select {
  width: 70%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .middle-subtitle {
    width: 60%;
    margin-top: 20px;
  }
}
