.modal-order {
    margin-top: 20%;
    width: 70%;
    margin: 0 auto;
    background-color: white;
    padding: 30px;
    color: black;
    box-shadow: 7px 6px 11px rgba(66, 66, 66, 0.1);
    border-radius: 10px;
    overflow: auto;
    height: 85%;

    &:focus {
        border: 5px solid #d2deed;
    }
}

.modal-order-confirm {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
    top: 20%;
    position: relative;
    width: 22%;
    margin: 0 auto;
    background-color: white;
    padding: 30px;
    color: black;
    box-shadow: 7px 6px 11px rgba(66, 66, 66, 0.1);
    border-radius: 10px;
    overflow: auto;
    height: 240px;
    border: 5px solid #d2deed;
    border-radius: 8px;
}

.btn-modal.order {
    cursor: pointer;
    border: 1px solid lightgray;
    color: black;

    text-align: center;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    font-family: Manrope;
    font-size: medium;
    background-color: #acd1ff;
    background: linear-gradient(180deg, #d9e7fc, #bcd9f4, #accaf5);
    width: 135px;
    border: 1px solid #4261b5;
    &:hover {
        box-shadow: 0 0 5px 0 #6c95d3 inset, 0 0 1px 1px #6ab7ff;
        font-weight: bold;
    }
    &:focus {
        border: 1px solid #6c95d3;
        outline: none;
        box-shadow: none;
    }
}
.modal-close-btn-panel {
    display: flex;
    justify-content: flex-end;
}

.modal-close-button {
    cursor: pointer;
    border: 1px solid lightgray;
    color: black;

    text-align: center;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    font-family: Manrope;
    font-size: medium;
    background-color: #acd1ff;
    background: linear-gradient(180deg, #d9e7fc, #bcd9f4, #accaf5);
    width: 100px;
}

.product-presentation-icon {
    display: inline;
}
/* .btn-modal {
    cursor: pointer;
    border: 1px solid lightgray;
    color: black;

    text-align: center;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;

    &.order {
        background-color: #abd7f5;
        width: 120px;
        &:hover {
            box-shadow: 0 0 5px 0 #6c95d3 inset, 0 0 1px 1px #6ab7ff;
        }
        &:active {
            background-color: #6ab7ff;
        }
    }

    &.close {
        background-color: gray;
        width: 80px;
    }
} */

.error {
    display: inline-block;
    color: red;
    margin-left: 15px;
}

.modal-label {
    display: block;
    margin-bottom: 10px;
}

.modal-text {
    width: 150px;
    display: inline-block;
    text-align: right;
    margin-right: 20px;
}

.order-form,
.order-form-data {
    padding: 0px 30px;
}

.order-form-title {
    background-image: linear-gradient(45deg, #b4bddc, #e0edf5, #b4bddc);
    text-align: center;
    padding: 10px;
}

.order-input {
    padding: 9px 15px;
    font-size: 15px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cecece;
    border-radius: 4px;
}

input:-internal-autofill-selected {
    background-color: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: none;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}

.loader-wait {
    background: rgba(254, 254, 254, 0.5);
    opacity: 0.9;
    border: 1px solid rgb(240, 240, 240);
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-any-link: none;
}

#company input[type="checkbox"] {
    height: 20px;
    width: 20px;
}

.order-input.obdobje-najema {
    max-width: 220px;
    margin-left: 10px;
    padding: 9px 4px;
    font-family: inherit;
    font-weight: bold;
}

.send-error {
    color: brown;
    font-weight: bold;
    font-size: larger;
}

.send-order-choice {
    display: inline-block;
    font-weight: bold;
}

.send-order-price {
    font-size: large;
    font-weight: bold;
}

.close-icon {
    width: 20px;
    cursor: pointer;
    opacity: 0.8;

    &.confirm {
        width: 10px;
    }
}
.confirm-order-div {
    display: flex;
    align-items: flex-end;
}

.confirm-order-btn {
    width: 120px;
}

.modal-close-txt {
    font-size: large;
    display: inline-block;
    margin-left: 10px;
}

.send-mail-div {
    margin-top: 20px;
}

button.send-mail-btn {
    cursor: pointer;
    border: 1px solid lightgray;
    color: black;

    text-align: center;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    font-family: Manrope;
    font-size: larger;
    background-color: #709bd4;
    border: 1px solid #4261b5;
    background: linear-gradient(180deg, #d9e7fc, #bcd9f4, #accaf5);
    /* background-color: #acd1ff;
    background: linear-gradient(180deg, #d9e7fc, #bcd9f4, #accaf5);
    */
    width: 155px;
    &:hover {
        box-shadow: 0 0 5px 0 #6c95d3 inset, 0 0 1px 1px #6ab7ff;
        border: 1px solid #4261b5;
        font-weight: bold;
    }
    &:focus {
        border: 1px solid #6c95d3;
        outline: none;
        box-shadow: none;
    }
}

@media only screen and (max-device-width: 480px) {
    .modal-order {
        width: 95%;
        padding: 20px 5px;
    }

    .order-form-data {
        padding: 0px 10px;
    }
    @media only screen and(orientation: portrait) {
        .order-form {
            padding: 0px 5px;
        }
        .modal-text {
            text-align: left;
        }
        .modal-label {
            width: auto;
        }
        .terms-label {
            width: auto;
        }
    }
}

@media only screen and (max-device-width: 768px) {
    @media only screen and(orientation: landscape) {
        .modal-text {
            text-align: left;
        }
    }
}

@media only screen and(min-device-width: 600px) and(max-device-width: 960px) {
    @media only screen and(orientation: portrait) {
        .modal-text {
            text-align: left;
        }
    }
}
