.slider-div.vps {
  width: 500px;
  max-width: 100%;
}

.slider-div.vps > [data-reach-slider-input][data-orientation="horizontal"] {
  padding: 8px 12px;
}

.slider-div.vps > .slider > [data-reach-slider-track][data-orientation="horizontal"] {
  margin-top: -12px;
}

[data-reach-slider-handle] .vps {
  width: 12px;
}

.product-title-div {
  font-size: medium;
}

.product-title-div.vps {
  font-size: large;
}

.slider-number.vps {
  font-weight: normal;
  font-size: medium;
}

@media only screen and (max-device-width: 319px) and (orientation: portrait) {
  .slider-div.vps {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .product-title-div.vps {
    text-align: left;
    font-size: large;
    font-weight: bold;
  }
  .slider-div.vps {
    width: 100%;
    margin: 0;
  }

  .vps-div {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .slider-number.vps {
    font-size: x-small;
  }

  .vps-hr {
    width: 80%;
  }
}

@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) {
  .product-title-div.vps {
    text-align: left;
    font-size: large;
    font-weight: bold;
  }
  .slider-div.vps {
    width: 92%;
    margin: 0;
  }
}
