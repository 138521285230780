.loader {
    width: 200px;
    height: 200px;
    background-color: rgb(96, 112, 202);
    z-index: 99999;
}

.loader-parent {
    position: fixed;
    width: 100%;
    height: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    top: 0;
    z-index: 99999;
}

.loader-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-text {
    width: 100%;
    text-align: center;
    font-size: x-large;
    margin-bottom: 30px;
}

$color-4: #acd1ff;
$color-3: #acd1ff;
$color-2: #64b6e6;
$color-1: #6390e4;
$color-5: #6390e4;

$color: $color-1, $color-2, $color-3, $color-4, $color-5;

@mixin anim() {
    @keyframes scale {
        0% {
            transform: scale(1);
        }
        50%,
        75% {
            transform: scale(2.5);
        }
        78%,
        100% {
            opacity: 0;
        }
    }
}

.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100px;
    overflow: hidden;
    animation-delay: 1s;
}

@for $i from 1 through 5 {
    .item-#{$i} {
        width: 25px;
        height: 25px;
        background: #acd1ff;
        border-radius: 50%;
        @include anim();
        background-color: nth($color, $i);
        margin: 7px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: nth($color, $i);
            opacity: 0.7;
            animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
            animation-delay: 200ms * $i;
            transition: 0.5s all ease;
            transform: scale(1);
        }
    }
}
