.panel-div {
  color: black;
  width: 100%;
}

.subpanel-div {
  margin: 0 auto;
  max-width: 1000px;
}

.panel-div.middle-div {
  height: 12px;
  background-color: #eee;
  background: linear-gradient(45deg, #cacaca, #b3b0b0, #cfcfcf);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .panel-div {
    font-size: small;
  }
}
