.slider-div {
  width: 650px;
  max-width: 690px;
}

.slider-btn-text {
  text-align: center;
  font-weight: bold;
  display: block;
  margin-top: -5px;
}

.slider,
.slider-marker {
  cursor: pointer;
}

.slider-number {
  font-size: medium;
  display: inline-block;
  top: 36px;
  font-weight: bold;
  display: block;
  margin-top: 17px;
}

.price-div {
  font-size: medium;
  margin-top: 50px;
}

.price-num {
  margin-left: 10px;
  font-size: large;
  font-weight: bold;
}

[data-reach-slider-track][data-orientation="horizontal"] {
  width: 100%;
  height: inherit;
  height: 12px;
  top: 20px;
}

[data-reach-slider-input][data-orientation="horizontal"] {
  width: auto;
  max-width: 100%;
  height: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding: 25px 20px;
}

[data-reach-slider-track][data-orientation="horizontal"] {
  width: 100%;
  height: inherit;
  height: 12px;
  margin-top: -10px;
}

[data-reach-slider] {
  background-color: #b5c5da;
  background: linear-gradient(30deg, #b5c5da, #cddaeefa, #b5c5da);
}

[data-reach-slider-marker][data-state="at-value"],
[data-reach-slider-marker][data-state="under-value"] {
  background: unset;
}

[data-reach-slider-marker][data-orientation="horizontal"] {
  top: 28px;
  transform: translate(-50%, -50%);
}

.slider-predefined-btn {
  border: 1px solid #8690c3;
  background: linear-gradient(45deg, #cadefa, #f0f4fa);
  height: 30px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  &:hover {
    background: linear-gradient(45deg, #dceffc, #dceffc);
  }
}

[data-reach-slider-marker] {
  background: none;
  background-color: none;
  border: none;
  -webkit-transform-origin: center;
  transform-origin: center;
}

/* [data-reach-slider-input][data-orientation="horizontal"] {
  width: 600px;
} */

[data-reach-slider-handle] {
  height: 30px;
  width: 15px;
  border-radius: 0;
  background-color: navy;
  border: 2px solid #1159a6;
  background: #fff;
  z-index: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.button.slider-predefined-btn {
  background-color: transparent;
  border: none;
}

@media only screen and (max-device-width: 768px) {
  .slider-div {
    width: 690px;
    padding: 0px 0px;
    margin: 0px auto;
    max-width: 690px;
  }

  .slider-predefined-btn {
    padding: 10px;
    margin: 10px;
    border-radius: 6%;
    background-color: #dfe9f0;
    width: 17%;
    height: 50px;
    text-align: center;
  }

  .price {
    padding: 10px 25px;
  }
}

@media only screen and (max-device-width: 319px) and (orientation: portrait) {
  .slider-div {
    width: 100%;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  .slider-div {
    width: 100%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
  .slider-div {
    width: 100%;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 767px) {
  .slider-div {
    width: 100%;
  }
}
